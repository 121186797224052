/*

Pagination - get pagination "visible buttons"
More info: https://stackoverflow.com/questions/58431700/how-can-i-limit-the-amount-of-pages-shown-in-pagination

 */
import { OrderItemAttribute, OrderStatus } from '../services/api/order/OrderService.types'
import { deepFilter, deepForEach } from 'react-children-utilities'
import { environmentConfig } from '../config/environment/environmentConfig'
import { getFunctionName } from './StringHelper'
import { round } from 'lodash-es'
import React, { ReactElement, ReactNode } from 'react'

export const getPageOptions = (pageCount: number, page: number, maxNumberOfOptions: number) => {
    const options = []
    const pivot = Math.ceil(maxNumberOfOptions / 2)
    if (pageCount <= maxNumberOfOptions) {
        while (options.length <= pageCount) {
            options.push(options.length + 1)
        }
    } else if (page < pivot) {
        while (options.length < maxNumberOfOptions) {
            options.push(options.length + 1)
        }
    } else if (page > pageCount - pivot) {
        while (options.length < maxNumberOfOptions) {
            options.unshift(pageCount - options.length + 1)
        }
    } else {
        for (let i = page - (pivot - 1); options.length < maxNumberOfOptions; i++) {
            options.push(i + 1)
        }
    }
    return options
}

export const findChildrenElement = <P>(children: ReactNode | undefined, componentName: string) => {
    const filteredChildren = assignPropsToChildrenElements<P>(
        React.Children.toArray(children).filter(item => {
            return React.isValidElement(item) && getFunctionName(item.type) === componentName
        })
    )
    return filteredChildren.length ? filteredChildren[0] : undefined
}

export const filterChildrenElements = <P>(children: ReactNode | undefined, componentName: string) => {
    return assignPropsToChildrenElements<P>(
        React.Children.toArray(children).filter(item => {
            return React.isValidElement(item) && getFunctionName(item.type) === componentName
        })
    )
}

export const deepFilterChildrenElements = <P>(children: ReactNode | undefined, componentName: string) => {
    return deepFilter(children, item => {
        if (!React.isValidElement<P>(item)) {
            return false
        }
        const element = item as ReactElement<P>
        return getFunctionName(element.type) === componentName
    })
}

export const deepCountChildrenElements = (children: ReactNode | undefined, componentName: string) => {
    let count = 0
    deepForEach(children, item => {
        if (!React.isValidElement(item)) {
            return false
        }
        const element = item as ReactElement
        if (getFunctionName(element.type) === componentName) {
            count++
        }
    })
    return count
}

export const assignPropsToChildrenElements = <P>(children?: ReactNode) => {
    return React.Children.toArray(children)
        .filter(item => {
            return React.isValidElement<P>(item)
        })
        .map(item => {
            return item as ReactElement<P>
        })
}

export const fileToDataUri = (file: File | Blob) => {
    return new Promise<string | undefined>(resolve => {
        const reader = new FileReader()
        reader.onload = event => {
            const result = event.target && event.target.result ? (event.target.result as string) : undefined
            resolve(result)
        }
        reader.readAsDataURL(file)
    })
}

export const booleanAsNumber = (value: boolean) => {
    return value ? 1 : 0
}
export const numberAsBoolean = (value: number) => {
    return value === 1
}
export const stringAsBoolean = (value: string) => {
    return value.toLowerCase() === 'true'
}
export const anyAsNumber = (value: any) => {
    return parseInt(value)
}
export const anyAsBoolean = (value: any) => {
    if (typeof value === 'boolean') {
        return value
    }
    if (typeof value === 'number') {
        return numberAsBoolean(value)
    }
    if (typeof value === 'string') {
        return stringAsBoolean(value)
    }
    return false
}
export const booleanAsStringToCzech = value => {
    if (typeof value === 'boolean') {
        return value ? 'Ano' : 'Ne'
    }
    return value
}

export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const formatNumber = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const getFileExtension = (fileName: string) => {
    const fileNameParts = fileName.split('.')
    return fileNameParts.slice(-1)[0]
}

export const castValueToType = (value: unknown, type: string) => {
    //'string' | 'enum' | 'int' | 'bool' | 'text'
    switch (type) {
        case 'string':
            return (value || '') as string
        case 'enum':
            return value as string
        case 'int':
            return parseInt((value || '0') as string)
        case 'bool':
            return typeof value === 'undefined' || value === null ? null : anyAsBoolean((value || 'false') as string)
        case 'text':
            return (value || '') as string
        default:
            return value
    }
}

export const isNumeric = (str?: unknown) => {
    if (typeof str === 'number') {
        return true
    }
    if (typeof str !== 'string') {
        return false
    }
    return !isNaN(parseInt(str)) && !isNaN(parseFloat(str))
}

export const extractNumberAndCurrency = (inputString: string, currency?: string) => {
    if (currency) {
        currency = currency.replaceAll('EUR', '€').replaceAll('CZK', 'Kč').replaceAll('.', ',')
    }
    const price = inputString.replaceAll('EUR', '€').replaceAll('CZK', 'Kč').replaceAll('.', ',')
    if (price.includes('€') || price.includes('Kč')) {
        return price
    }
    return `${price} ${currency}`
}

export const formatCurrency = (value: number | string, currency: string = 'CZK') => {
    if (typeof value === 'string') {
        return extractNumberAndCurrency(value, currency)
    }
    if (currency === null) {
        currency = 'CZK'
    }
    const formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency
    })
    return formatter.format(value)
}

export const formatCurrencyText = (currency: string = 'CZK') => {
    if (currency === 'CZK') {
        return 'Kč'
    }
    return '€'
}

export const roundedValueToTwoDecimals = (value: number) => {
    return Math.round(value * 100) / 100
}

export const calculatePercentage = (originalPrice: number, percentage: number) => {
    const percentageValue = originalPrice * (percentage / 100)
    return round(percentageValue, 2)
}

export const buildDate = (builder: (current: Date) => Date) => {
    return builder(new Date())
}

export const rangeBetweenNumbers = (start: number, stop?: number, stepSize = 1): number[] => {
    if (stop == null) {
        stop = start
        start = 1
    }

    const steps = (stop - start) / stepSize

    const set = []
    for (let step = 0; step <= steps; step++) {
        set.push(start + step * stepSize)
    }

    return set
}

export const asObject = (obj: unknown) => {
    return obj as Record<string, unknown>
}

export const buildEnvironmentApiUrl = (hostname?: string) => {
    if (!hostname && typeof window !== 'undefined') {
        return environmentConfig.apiUrlBuilder.replace(/{hostname}/g, window.location.hostname)
    }
    return environmentConfig.apiUrlBuilder.replace(/{hostname}/g, hostname)
}

export const createRedirectDemandUrl = (status: OrderStatus, id: number, step: number) => {
    if (status === 'I') {
        return `/app/demand/create/${id}?edit=true&step=${step}`
    }
    return `/app/demand/${id}`
}

export const createArrayFromStrings = (item: OrderItemAttribute) => {
    if (item.type === 'counted_enum' && typeof item.value === 'string') {
        const values = item.value.split(';')

        values.pop()
        const array = values.map(element => {
            const splitedElement = element.split('[')
            const value = splitedElement[0]
            const amount = splitedElement[1]?.split(']')[0]
            const object = {
                value: value,
                amount: amount
            }
            return object
        })
        return array
    }
}

export const splitCountedEnum = (printValue: string): string[] => {
    return printValue.split(';')
}

export const isListContentHelper = (htmlContent: string): boolean => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, 'text/html')
    return !!doc.querySelector('ul, ol')
}

export const convertSvgToBase64 = (svg: string) => `data:image/svg+xml;base64,${btoa(svg)}`
